function gptwNavResize() {
	var helloBarHeight = jQuery('#hello-bar').height();
	document.querySelector('.main-body header').style.top = -(jQuery('#gptw-navigation .desktop-top').height() + helloBarHeight + 2) + 'px';
	document.querySelector('.main-body .mobile-header').style.top = helloBarHeight + 'px';

	if (document.querySelector('.main-body .gptw-navigation')) {
		document.querySelector('.main-body .gptw-navigation').style.top = helloBarHeight + 'px';
	}

	if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > globalVariables.breakpoints.lg - 1) {
		document.body.style.paddingTop = 0;

		document.getElementById('hello-bar').style.position = 'static';

		document.querySelector('.main-body .mobile-middle').style.top = 0 + 'px';

		jQuery('#gptw-navigation nav.dropdown>ul>li')
			.mouseenter(function () {
				jQuery(this).addClass('show'); //this is to allow arrow animation
				jQuery(this).find('>ul').addClass('show');
			})

			.mouseleave(function () {
				jQuery(this).removeClass('show'); //this is to allow arrow animation
				jQuery(this).find('>ul').removeClass('show');
			});

		//third tier
		jQuery('#gptw-navigation nav.dropdown>ul>li>ul>li')
			.mouseenter(function () {
				jQuery(this).find('>ul').addClass('show');

				var element = jQuery(this).find('>ul>li');

				if (
					element &&
					typeof element.offset() !== 'undefined' &&
					element.offset().left + element.width() > jQuery(window).width()
				) {
					element.parent().addClass('left');

					var container = element.parent().parent().parent();

					element.parent().css('left', -(container.outerWidth() + element.outerWidth() - container.outerWidth() + 11));
				}
			})

			.mouseleave(function () {
				jQuery(this).find('>ul').removeClass('show');
			});

		jQuery('#gptw-navigation').removeClass('open');
		jQuery('body').removeClass('fixed');
		jQuery('#mobile-overlay').removeClass('show');
		jQuery('#mobile-nav-close').hide();
		jQuery('#mobile-nav-open').show();
	} else {
		document.getElementById('hello-bar').style.position = 'fixed';

        document.querySelector('.main-body .mobile-middle').style.top = helloBarHeight + 'px';

		document.body.style.paddingTop = jQuery('#hello-bar').height() + jQuery('.mobile-header').first().height() + 'px';

		jQuery('#gptw-navigation nav.dropdown>ul>li').click(function () {
			jQuery(this).parent().find('ul').removeClass('show');

			jQuery(this).find('ul').toggleClass('show');
		});
	}
}

jQuery(document).ready(function () {
	gptwNavResize();

	jQuery(window).resize(function () {
		gptwNavResize();
	});

	jQuery('#mobile-nav-open').click(function () {
		jQuery('#gptw-navigation').toggleClass('open');
		jQuery('body').toggleClass('fixed');
		jQuery('#mobile-overlay').toggleClass('show');
		jQuery('#mobile-nav-close').show();
		jQuery('#mobile-nav-open').hide();
	});
	jQuery('#mobile-nav-close').click(function () {
		jQuery('#gptw-navigation').toggleClass('open');
		jQuery('body').toggleClass('fixed');
		jQuery('#mobile-overlay').toggleClass('show');
		jQuery('#mobile-nav-close').hide();
		jQuery('#mobile-nav-open').show();
	});

	var gptwCountryOpen = false;

	jQuery('#country-selector img, #country-selector i').bind('mousedown touchstart', function (e) {
		e.stopImmediatePropagation();
		e.preventDefault();

		if (gptwCountryOpen === false) {
			jQuery('#country-selector form').show();

			jQuery('#country-selector select').select2('open');

			gptwCountryOpen = true;
		} else {
			jQuery('#country-selector form').hide();

			jQuery('#country-selector select').select2('close');

			gptwCountryOpen = false;
		}
	});

});
